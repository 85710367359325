// extracted by mini-css-extract-plugin
export var arrow = "Services-module--arrow--14b9a";
export var button = "Services-module--button--21f70";
export var buttonText = "Services-module--button-text--c2498";
export var checkSvg = "Services-module--check-svg--1f6d4";
export var container = "Services-module--container--8cc2f";
export var content = "Services-module--content--feddc";
export var contentContainer = "Services-module--content-container--dea4d";
export var desc = "Services-module--desc--51dfb";
export var descContainer = "Services-module--desc-container--b4b5d";
export var imageContainer = "Services-module--image-container--5b1a9";
export var item = "Services-module--item--db0e1";
export var itemContainer = "Services-module--item-container--ea251";
export var itemText = "Services-module--item-text--84813";
export var linkContainer = "Services-module--link-container--2f52a";
export var wrapper = "Services-module--wrapper--35995";