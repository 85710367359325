import React from 'react'
import parse from 'html-react-parser'

import * as cx from './TabContent.module.scss'

const TabContent = ({
  heading,
  headingClassName,
  description,
  descriptionClassName,
}) => {
  return (
    <section id="tabs-content" className={cx.wrapper}>
      <div className={`${cx.container} container`}>
        <div className={cx.contentContainer}>
          <h1 className={headingClassName}>{heading}</h1>
          <p className={descriptionClassName}>{parse(description)}</p>
          <hr />
        </div>
      </div>
    </section>
  )
}

export default TabContent
