export const pageData = {
  title: 'Custom Marketing Solutions',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'Real Estate Digital Marketing',
      url: '/real-estate-digital-marketing/',
    },
    {
      text: 'Custom Marketing Solutions',
      url: '/real-estate-digital-marketing/custom-marketing-solutions/',
    },
  ],
  uri: '/real-estate-digital-marketing/custom-marketing-solutions/',
  buttonsHeight: { desktop: 1880, tablet: 2200, phone: 2880 },
  lcf: {
    titleClassName: 'heading-2',
  },
}

export const bannerData = {
  layout: { container: 'product-absolute-custom' },
  heading: {
    text: 'Custom Marketing Solutions',
    className: 'heading-1',
  },
  subheading: {
    text: 'MORE TOOLS FOR YOUR SUCCESS',
    className: 'subtitle-7',
  },
  paragraph: {
    text:
      'Our specialized real estate marketing services give you more ways to reach your target audiences.',
    className: 'default-body',
  },
}

export const tabsData = {
  activeTab: 5,
  tabsClassName: 'small-heading',
  heading: 'Beyond The Usual',
  headingClassName: 'heading-2',
  description:
    'You’ve got your digital marketing basics covered.<br/> Now you need advanced real estate marketing techniques to further amplify your brand. <p/><p/>Think of our custom marketing solutions as premium services that you can tap whenever you need them.',
  descriptionClassName: 'subtitle-5',
}

export const servicesData = [
  {
    heading: 'Google Screened',
    headingClassName: 'heading-3',
    subheading:
      'Real estate is all about reputation. And<br/> who better to vouch for yours than Google itself? Our team will help you earn a<br/> Google Screened badge, which indicates that you’ve met the exacting background checks and qualifications set by the search giant.',
    subheadingClassName: 'subtitle-5',
    descList: [
      'Establish your credibility with<br/> Google’s backing',
      'Gain an edge over non-certified agents',
      'Preferential exposure for your local PPC ads',
    ],
    textClassName: 'subtitle-5',
    hasLink: false,
    linkName: 'Sample Video',
    link: '/',
    linkNameClassName: 'button-text',
  },
  {
    heading: 'Press Release Creation & Distribution',
    headingClassName: 'heading-3',
    subheading:
      'Press releases aren’t just a way to share important announcements — they’re also a clever complement to your SEO strategy. After writing your press release, we’ll distribute it to high-authority news sites, which gives your brand extra credibility and visibility on search engines.',
    subheadingClassName: 'subtitle-5',
    descList: [
      'Bolster your trustworthiness by being featured on top news websites',
      'Generate earned media mileage',
      'Attract more website traffic and leads',
    ],
    textClassName: 'subtitle-5',
    hasLink: false,
    linkName: 'Sample Video',
    link: '/',
    linkNameClassName: 'button-text',
  },
  {
    heading: 'Video SEO For WordPress',
    headingClassName: 'heading-3',
    subheading:
      'You’ve got informative videos that contain valuable information. We’ve got the SEO tools to make them easier to find online.',
    subheadingClassName: 'subtitle-5',
    descList: [
      'Greater search visibility for your video content',
      'Drive more traffic to the page where your video is embedded',
      'Done-for-you setup for all major video-hosting platforms',
    ],
    textClassName: 'subtitle-5',
    hasLink: false,
    linkName: '',
    link: '/',
    linkNameClassName: 'button-text',
  },
  {
    heading: 'Video Walkthroughs',
    headingClassName: 'heading-3',
    subheading:
      'Bring your listings to life with branded video tours that are the next best thing to seeing a property in person.',
    subheadingClassName: 'subtitle-5',
    descList: [
      'Engage prospective buyers with immersive video walkthroughs',
      'Branding integration to ensure that your video matches your website',
      'Freedom to publish content on any video hosting platform of your choice',
    ],
    textClassName: 'subtitle-5',
    hasLink: true,
    linkName: 'Sample Video',
    link: 'https://vimeo.com/101375994',
    linkNameClassName: 'button-text',
  },
  {
    heading: 'Other Custom Solutions',
    headingClassName: 'heading-3',
    subheading:
      'No matter what your marketing needs may be, our team can handle it for you.',
    subheadingClassName: 'subtitle-5',
    descList: [
      'Infographics creating',
      'Web directory submission',
      'Email marketing',
      'Downloadable PDF creation',
      'Dedicated virtual assistants',
      'Facebook ads management',
    ],
    textClassName: 'subtitle-5',
    hasLink: false,
    linkName: '',
    link: '/',
    linkNameClassName: 'button-text',
  },
]
