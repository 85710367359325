import React, { useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import { useHomePageMetadata } from '@hooks/home-page-metadata'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'
import { wpautop } from '@utils/wpautop'
import parse from 'html-react-parser'

import ElementImage from '@components/global/element/Image'
import * as cx from './DigitalMarketing.module.scss'

const DigitalMarketing = ({ bgImage }) => {
  const data = useHomePageMetadata().wp.DigitalMarketing

  return (
    <section
      id="social-media"
      className={`${cx.section}`}
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className={`${cx.heading}`}>
        <h2>
          <span className="subtitle-1">{parse(data.title)}</span>
          <span className="heading-2">{parse(data.subtitle)}</span>
        </h2>

        {parse(wpautop(data.description, 'subtitle-5'))}
      </div>

      <div className={cx.sliders}>
        <Splide
          hasTrack={false}
          tag="div"
          options={{
            arrows: false,
            arrowPath: false,
            pagination: false,
            perPage: 5,
            perMove: 1,
            autoHeight: false,
            lazyLoad: 'sequential',
            autoplay: false,
            interval: 5000,
            isNavigation: true,
            gap: '0.813rem',
            autoWidth: true,
            breakpoints: {
              991: {
                perPage: 3,
              },
            },
          }}
          className={`${cx.thumb}`}
        >
          <SplideTrack>
            {data.products.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  <ElementImage
                    src={item?.image?.url}
                    width={item?.image?.width}
                    height={item?.image?.height}
                    alt={item.image.alt}
                    srcSet={item?.image?.srcset}
                    sizes={item?.image?.sizes}
                    splideLazyload={true}
                  />
                  <canvas width={294} height={220}></canvas>
                  <span className="subtitle-4">{item.title}</span>
                </SplideSlide>
              )
            })}
          </SplideTrack>
        </Splide>
      </div>
    </section>
  )
}

export default DigitalMarketing
