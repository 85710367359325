import { useStaticQuery, graphql } from 'gatsby'

/* custom-marketing-solutions */

const useCustomMarketingSolutionsMetadata = () => {
  const query = useStaticQuery(graphql`
    query customMarketingSolutionsMetadata {
      bannerBG: file(
        relativePath: { eq: "custom-marketing-solutions/banner-bg.png" }
      ) {
        id
        ...CustomMarketingSolutionsImageOptimize
      }
      bannerImg: file(
        relativePath: { eq: "custom-marketing-solutions/banner-image.png" }
      ) {
        id
        ...CustomMarketingSolutionsImageOptimize
      }
      dmBG: file(
        relativePath: {
          eq: "custom-marketing-solutions/digital-marketing-bg.png"
        }
      ) {
        id
        ...CustomMarketingSolutionsImageOptimize
      }
      servicesBG: file(
        relativePath: { eq: "custom-marketing-solutions/services-bg.png" }
      ) {
        id
        ...CustomMarketingSolutionsImageOptimize
      }
      services1: file(
        relativePath: { eq: "custom-marketing-solutions/services-1.png" }
      ) {
        id
        ...CustomMarketingSolutionsImageOptimize
      }
      services2: file(
        relativePath: { eq: "custom-marketing-solutions/services-2.png" }
      ) {
        id
        ...CustomMarketingSolutionsImageOptimize
      }
      services3: file(
        relativePath: { eq: "custom-marketing-solutions/services-3.png" }
      ) {
        id
        ...CustomMarketingSolutionsImageOptimize
      }
      services4: file(
        relativePath: { eq: "custom-marketing-solutions/services-4.png" }
      ) {
        id
        ...CustomMarketingSolutionsImageOptimize
      }
      services5: file(
        relativePath: { eq: "custom-marketing-solutions/services-5.png" }
      ) {
        id
        ...CustomMarketingSolutionsImageOptimize
      }
    }

    fragment CustomMarketingSolutionsImageOptimize on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }
  `)

  return query
}

export default useCustomMarketingSolutionsMetadata
