import React, { useRef } from 'react'
import ElementImage from '@components/global/element/Image'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import SvgCheckedCircle from '@svg/checkedCircle.inline'
import SvgArrowAlt from '@svg/arrowAlt.inline'
import { Link } from 'gatsby'
import clsx from 'clsx'
import parse from 'html-react-parser'

import * as cx from './Services.module.scss'

const ServicesItem = React.forwardRef(({ item, image }, ref) => {
  const linkElement = item.hasLink && (
    <div className={cx.linkContainer}>
      <a
        className={clsx(cx.buttonText, 'button-text')}
        href={item.link}
        target="_blank"
        rel="noreferrer"
      >
        {item.linkName}
        <SvgArrowAlt className={cx.arrow} />
      </a>
    </div>
  )

  return (
    <li className={cx.itemContainer}>
      <div className={cx.item}>
        <div className={cx.imageContainer}>
          <ElementImage src={image ? image : 'none'} alt={'Device Image'} />
        </div>
        <div className={cx.contentContainer}>
          <div className={cx.content}>
            <h1 className={item.headingClassName}>{item.heading}</h1>
            <h3 className={item.subheadingClassName}>
              {parse(item.subheading)}
            </h3>
            <div className={cx.descContainer}>
              <h4 className={'subtitle-6'}>KEY BENEFITS:</h4>
              {item.descList?.map((desc, index) => {
                return (
                  <div key={index} className={cx.desc}>
                    <div>
                      <SvgCheckedCircle className={cx.checkSvg} />
                    </div>
                    <div>
                      <p className={`${cx.itemText} ${item.textClassName}`}>
                        {parse(desc)}
                      </p>
                    </div>
                  </div>
                )
              })}
            </div>
            {linkElement}
            <button
              className={`${cx.button} primary-button`}
              onClick={(e) => ref.current.openModal()}
            >
              learn more
            </button>
          </div>
        </div>
      </div>
      <hr />
    </li>
  )
})

const Services = ({ content, images, bgImage }) => {
  const modalFunc = useRef()

  return (
    <section
      id="services"
      className={cx.wrapper}
      style={{
        backgroundImage: `url(${bgImage ? bgImage : 'none'})`,
      }}
    >
      <ModalForm
        childFunc={modalFunc}
        desktopTitle="Sign Up Today <br/>For A Free Consultation"
        mobileTitle="Sign Up Today For A Free Consultation"
        description="See how Agent Image can help you grow your real estate business."
        template="m1"
        type="a"
      >
        <FormSignUp
          submitLabel="Get started today"
          messageLabel="Questions or Comments"
        />
      </ModalForm>
      <ul className={`${cx.container} container`} id="services-list">
        {content?.map((item, index) => {
          return (
            <ServicesItem
              ref={modalFunc}
              key={index}
              item={item}
              image={images[index]}
            />
          )
        })}
      </ul>
    </section>
  )
}

export default Services
