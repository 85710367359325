import React from 'react'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Banner from '@components/global/banner/Banner'

import TabContent from '@components/pages/custom-marketing-solutions/TabContent'
import Services from '@components/pages/custom-marketing-solutions/Services'
import DigitalMarketing from '@components/pages/custom-marketing-solutions/DigitalMarketing'

import Seo from '@components/seo/Index'
import Tabs from '@components/global/tabs/DigitalMarketing'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'

import {
  pageData as page,
  bannerData,
  tabsData,
  servicesData,
} from '@src/data/CustomMarketingSolutions'
import useCustomMarketingSolutionsMetadata from '@hooks/custom-marketing-solutions-metadata'

import * as cx from './CustomMarketingSolutions.module.scss'

const CustomMarketingSolutions = () => {
  const {
    bannerBG,
    bannerImg,
    dmBG,
    servicesBG,
    services1,
    services2,
    services3,
    services4,
    services5,
  } = useCustomMarketingSolutionsMetadata()

  const servicesImages = [
    extractImage(services1),
    extractImage(services2),
    extractImage(services3),
    extractImage(services4),
    extractImage(services5),
  ]

  const modalFunc = React.useRef()

  return (
    <LayoutInnerPage buttonsHeight={page.buttonsHeight}>
      <Seo title={page.title} uri={page.uri} />
      <Banner
        layout={bannerData.layout.container}
        breadcrumbs={page.breadcrumbs}
        wrapperClassName={cx.banner}
        bgImage={extractImage(bannerBG)}
        bannerImage={extractImage(bannerImg)}
        title={bannerData.heading.text}
        titleClassName={bannerData.heading.className}
        subtitle2={bannerData.subheading.text}
        subtitle2ClassName={bannerData.subheading.className}
        description={bannerData.paragraph.text}
        descriptionClassName={bannerData.paragraph.className}
        button_click={(e) => modalFunc.current.openModal()}
        button_text="Bolster your online presence"
        button_link="/"
      />
      <ModalForm
        childFunc={modalFunc}
        desktopTitle="Sign Up Today For A<br/> Free Consultation"
        mobileTitle="Sign Up Today For A<br/> Free Consultation"
        description="See how Agent Image can help you grow your real estate business."
        template="m1"
        type="a"
      >
        <FormSignUp
          submitLabel="Get started today"
          messageLabel="Questions or Comments"
        />
      </ModalForm>
      <Tabs
        tabsClassName={tabsData.tabsClassName}
        activeTab={tabsData.activeTab}
      />
      <TabContent
        heading={tabsData.heading}
        headingClassName={tabsData.headingClassName}
        description={tabsData.description}
        descriptionClassName={tabsData.descriptionClassName}
      />
      <Services
        bgImage={extractImage(servicesBG)}
        content={servicesData}
        images={servicesImages}
      />
      <DigitalMarketing bgImage={extractImage(dmBG)} />
      <LeadContactForm
        version="LCF02"
        modelOrBackground="Diana"
        background={null}
        title="Book a FREE Consultation!"
        subtitle="Interested in Custom Marketing?"
        submitLabel="Lets Do This!"
        // titleClassName={'heading-3'}
        leadName="Lead Contact Form"
        layout="B"
      />
    </LayoutInnerPage>
  )
}

export default CustomMarketingSolutions
